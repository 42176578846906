import $api from "../http";

export const getGpusListService = async (all = false) => {
  return await $api.get("/gpus/list", { params: { all } });
};

export const updateGpusService = async (gpus) => {
  return await $api.post("/gpus/update", { gpus });
};

export const connectToGetGpusWebSocket = () => {
  return new WebSocket(
    `${process.env.REACT_APP_HOST.replace("https", "wss")}gpus/list`
  );
};
