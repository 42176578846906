import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGpusList, updateGpus } from "../../../actions/gpus";
import { miningTableGetRevenue } from "../../../helpers/miningTable.helper";
import AdminGpusTableRow from "./row";

export default function AdminGpusTable() {
  const currentGpus = useSelector((state) => state.gpus.arr);

  const dispatch = useDispatch();

  const updatedIds = useRef([]);
  const processing = useRef(false);

  useEffect(() => {
    dispatch(getGpusList(true));
  }, [dispatch]);

  const saveHandler = async () => {
    if (processing.current) return;
    processing.current = true;

    const response = await updateGpus(
      currentGpus.filter((el) =>
        [...new Set(updatedIds.current)].includes(el._id)
      )
    );

    processing.current = false;

    if (response !== undefined) {
      updatedIds.current = [];
      alert("Success");
    } else {
      alert("Error");
    }
  };

  const getRevenue = (item) => {
    if (!item.have) return -Infinity;
    return miningTableGetRevenue(item);
  };

  const renderGpus = () => {
    const arr = currentGpus.sort((a, b) => getRevenue(b) - getRevenue(a));
    return arr.map((el, i) => (
      <AdminGpusTableRow key={i} el={el} updatedIds={updatedIds} />
    ));
  };

  return (
    <div className="panelContainer">
      <table className="panelTable">
        <thead>
          <tr>
            <th>Model</th>
            <th>Release Date</th>
            <th>Hashrate</th>
            <th>Revenue 24h</th>
            <th>Price</th>
            <th>Have</th>
          </tr>
        </thead>
        <tbody>{renderGpus()}</tbody>
      </table>
      <div className="panelButtonWrapper">
        <div className="panelBtn panelBtn-black" onClick={() => saveHandler()}>
          Save
        </div>
      </div>
    </div>
  );
}
