import { setFilter, setFilterReversed } from "../reducers/minersReducer";

export const tableHandleFilter = (
  dispatch,
  value,
  currentFilter,
  currentReversed
) => {
  if (value !== currentFilter) {
    dispatch(setFilter(value));
  } else {
    dispatch(setFilterReversed(!currentReversed));
  }
};

const getHashrate = (item) => {
  let hashrate = parseFloat(item.hashrate.replace(/[^-0-9]/gim, "").trim());
  if (item.hashrate.includes("kh/s")) {
    hashrate = hashrate * 1000;
  } else if (item.hashrate.includes("Mh/s")) {
    hashrate = hashrate * 1000000;
  } else if (item.hashrate.includes("Gh/s")) {
    hashrate = hashrate * 1000000000;
  } else if (item.hashrate.includes("Th/s")) {
    hashrate = hashrate * 1000000000000;
  } else if (item.hashrate.includes("Ph/s")) {
    hashrate = hashrate * 1000000000000000;
  } else if (item.hashrate.includes("Eh/s")) {
    hashrate = hashrate * Number.BigInt("1000000000000000000");
  }
  return hashrate;
};

const getPower = (item) => {
  const power = item.power.match(/[0-9]*W/g);

  if (power === null) return "";
  return power[0];
};

export const miningTableGetRevenue = (item) => {
  return parseFloat(item.revenue.substring(1));
};

export const miningTableGetProfit = (item, cost) => {
  let r = (
    miningTableGetRevenue(item) -
    (parseInt(getPower(item).slice(0, -1)) / 1000) * 24 * cost
  ).toFixed(2);
  return r;
};

const getPrice = (item, currentReversed) => {
  if (item.price < 0 && currentReversed) {
    return Infinity;
  } else {
    return item.price;
  }
};

export const miningTableGetPayback = (item, cost, currentReversed) => {
  let r = (item.price / miningTableGetProfit(item, cost) / 30).toFixed(1);
  if (r < 0 && !currentReversed) {
    return Infinity;
  }
  if (item.price < 0 && !currentReversed) {
    return Infinity;
  }
  if (item.price < 0 && currentReversed) {
    return -Infinity;
  }
  return r;
};

export const miningTableSort = (
  arr,
  currentMiners,
  currentFilter,
  currentReversed
) => {
  const _arr = arr.sort((a, b) => {
    if (currentReversed) {
      const c = a;
      a = b;
      b = c;
    }

    switch (currentFilter) {
      case 1:
        return getHashrate(b) - getHashrate(a);
      case 2:
        return (
          parseInt(getPower(b).slice(0, -1)) -
          parseInt(getPower(a).slice(0, -1))
        );
      case 3:
        return miningTableGetRevenue(b) - miningTableGetRevenue(a);
      case 4:
        return (
          miningTableGetProfit(b, currentMiners) -
          miningTableGetProfit(a, currentMiners)
        );
      case 5:
        return getPrice(b, currentReversed) - getPrice(a, currentReversed);
      case 6:
        return (
          miningTableGetPayback(a, currentMiners, currentReversed) -
          miningTableGetPayback(b, currentMiners, currentReversed)
        );

      default:
        return null;
    }
  });

  return _arr;
};
