import pages from "./pages.json";

import styles from "../styles/navbar/navbar.module.css";

export const SidebarData = [
  {
    title: "Доходность асиков",
    path: pages.index.url,
    icon: (
      <img src={"/assets/img/stats-chart-icon.svg"} alt={"Stats chart icon"} />
    ),
    cName: styles.navBarMenuText,
  },
  {
    title: "Доходность видеокарт",
    path: pages.gpus.url,
    icon: (
      <img src={"/assets/img/stats-chart-icon.svg"} alt={"Stats chart icon"} />
    ),
    cName: styles.navBarMenuText,
  },
  {
    title: "Лучшие прошивки",
    path: pages.firmware.url,
    icon: (
      <img
        src={"/assets/img/software-download-icon.svg"}
        alt={"Software download icon"}
      />
    ),
    cName: styles.navBarMenuText,
  },
];
