import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsics } from "../../actions/asics";
import {
  miningTableSort,
  tableHandleFilter,
} from "../../helpers/miningTable.helper";
import { updateAsicsList } from "../../reducers/asicReducer";
import { connectToGetAsicsWebSocket } from "../../services/asics.service";
import AsicRow from "../AsicRow/AsicRow";
import AsicRowModal from "../asicRowModal/AsicRowModal";

import "./asicTable.css";

export default function AsicsTable() {
  const currentAsics = useSelector((state) => state.asics.asics);
  const currentMiners = useSelector((state) => state.miners.cost);
  const currentFilter = useSelector((state) => state.miners.filer);
  const currentReversed = useSelector((state) => state.miners.filterReversed);

  const socket = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsics());
  }, [dispatch]);

  useEffect(() => {
    socket.current = connectToGetAsicsWebSocket();

    socket.current.onmessage = (event) => {
      const data = JSON.parse(event.data);

      dispatch(updateAsicsList(data));
    };

    return () => {
      socket.current.close();
    };
  }, [dispatch]);

  const handleFilter = (value) => {
    tableHandleFilter(dispatch, value, currentFilter, currentReversed);
  };

  const renderAsics = () => {
    const arr = miningTableSort(
      currentAsics,
      currentMiners,
      currentFilter,
      currentReversed
    );

    return arr.map((el, i) => {
      if (el.have) {
        return <AsicRow key={i} id={i} el={el} />;
      }
      return null;
    });
  };

  return (
    <div className="asicTableContainer">
      <table className="asicTable">
        <thead>
          <tr>
            <th className="text-left">Модель</th>
            <th className="text-center">Дата выпуска</th>
            <th
              className={[
                "text-right",
                "asicTableSelectable",
                currentFilter === 1 ? "asicRowSelected" : null,
              ].join(" ")}
              onClick={() => handleFilter(1)}
            >
              Хешрейт
            </th>
            <th
              className={[
                "text-right",
                "asicTableSelectable",
                currentFilter === 2 ? "asicRowSelected" : null,
              ].join(" ")}
              onClick={() => handleFilter(2)}
            >
              Потребление
            </th>
            <th className="text-center">Алгоритм</th>
            <th
              className={[
                "text-right",
                "asicTableSelectable",
                currentFilter === 3 ? "asicRowSelected" : null,
              ].join(" ")}
              onClick={() => handleFilter(3)}
            >
              Доход за 24 часа
            </th>
            <th
              className={[
                "text-right",
                "asicTableSelectable",
                currentFilter === 4 ? "asicRowSelected" : null,
              ].join(" ")}
              onClick={() => handleFilter(4)}
            >
              Прибыль за 24 часа
            </th>
            <th
              className={[
                "text-center",
                "asicTableSelectable",
                currentFilter === 5 ? "asicRowSelected" : null,
              ].join(" ")}
              onClick={() => handleFilter(5)}
            >
              Цена под ключ
            </th>
            <th
              className={[
                "text-left",
                "asicTableSelectable",
                currentFilter === 6 ? "asicRowSelected" : null,
              ].join(" ")}
              onClick={() => handleFilter(6)}
              colSpan={2}
            >
              Окупаемость, мес
            </th>
          </tr>
        </thead>
        <tbody>{renderAsics()}</tbody>
      </table>
      <AsicRowModal />
    </div>
  );
}
