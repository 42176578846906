import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import { FC } from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import { BlockTitle } from "../block";
import { FIRMWARE_REVIEWS } from "./reviews";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";

type Props = {};

const FirmwareReview: FC<Props> = () => {
  return (
    <div>
      <BlockTitle>Обзоры прошивок смотрите тут</BlockTitle>
      <Separator className="my-4" />
      <ScrollArea className="w-full whitespace-nowrap rounded-md">
        <div className="mx-auto flex w-max space-x-4 py-4">
          {FIRMWARE_REVIEWS.map((firmware) => (
            <figure key={firmware.id} className="w-[415px] shrink-0">
              <div className="overflow-hidden rounded-md">
                <LiteYouTubeEmbed
                  key={firmware.id}
                  id={firmware.id}
                  title={firmware.title}
                  wrapperClass={"yt-lite aspect-video w-full"}
                  playerClass={"lty-playbtn border-none"}
                />
              </div>
              <figcaption className="truncate pt-2 text-xs text-muted-foreground">
                {firmware.title}
              </figcaption>
            </figure>
          ))}
        </div>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </div>
  );
};

export default FirmwareReview;
