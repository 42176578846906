import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  miningTableGetPayback,
  miningTableGetProfit,
} from "../../helpers/miningTable.helper";
import { handleOpenModal } from "../modal/Modal";
import brands from "../../settings/gpuBrands.json";

import styles from "../../styles/gpus/row.module.css";

export default function GpusTableRow({ id, el }) {
  const currentMiners = useSelector((state) => state.miners.cost);
  const currentFilter = useSelector((state) => state.miners.filer);

  const dispatch = useDispatch();

  const profit = useMemo(
    () => miningTableGetProfit(el, currentMiners),
    [el, currentMiners]
  );

  return (
    <tr
      className={["asicRow", el.price < 0 ? "asicRowInactive" : null].join(" ")}
    >
      <td className="text-left">
        <div className="asicRowNameWrapper">
          <span
            className={[
              styles.brand,
              el.brand === brands.nvidia.title.toUpperCase()
                ? styles.nvidia
                : null,
              el.brand === brands.amd.title.toUpperCase() ? styles.amd : null,
            ].join(" ")}
          >
            {el.brand}
          </span>
          <div className="asicRowName">{el.title}</div>
        </div>
      </td>
      <td className="text-center">{el.release}</td>
      <td
        className={[
          "text-right",
          currentFilter === 1 ? "asicRowSelected" : null,
        ].join(" ")}
      >
        <p>{el.hashrate}</p>
        <p
          className={[
            styles.power,
            currentFilter === 1 ? styles.selected : null,
          ].join(" ")}
        >
          {el.power}
        </p>
      </td>
      <td
        className={[
          "text-right",
          currentFilter === 3 ? "asicRowSelected" : null,
        ].join(" ")}
      >
        {el.revenue}
      </td>
      <td
        className={[
          "text-right",
          currentFilter === 4 ? "asicRowSelected" : null,
        ].join(" ")}
      >{`$${profit}`}</td>
      <td
        className={[
          "text-center",
          "asicRowPrice",
          currentFilter === 5 ? "asicRowSelected" : null,
        ].join(" ")}
      >
        {el.price > 0 ? "$" + el.price : "Нет в наличии"}
      </td>
      <td
        className={[
          "text-left",
          "asicRowPrice",
          currentFilter === 6 ? "asicRowSelected" : null,
        ].join(" ")}
      >
        {el.price > 0 &&
          (profit > 0
            ? miningTableGetPayback(el, currentMiners, null) + " Мес."
            : "Не окупается")}
      </td>
      <td className="text-left">
        <div
          className={[
            "asicRowButton",
            el.price < 0 ? "asicRowButtonInactive" : null,
          ].join(" ")}
          onClick={() => handleOpenModal(dispatch, id)}
        >
          <div className="asicRowButtonText">Купить</div>
        </div>
      </td>
    </tr>
  );
}
