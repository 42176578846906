import axios from "axios";

const $api = axios.create({
  withCredentials: true,
  baseURL: `${process.env.REACT_APP_HOST}api`,
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    if (error.response.status === 401 && error.config) {
      window.location.reload();
      return;
    }
    throw error;
  }
);

export default $api;
