import { FC } from "react";
import FirmwareAdvantages from "./components/firmware-advantages";
import FirmwareReview from "./components/firmware-review";
import FirmwareList from "./components/firmware-list";

type Props = {};

const FirmwarePage: FC<Props> = () => {
  return (
    <div className="container space-y-8 py-8">
      <h1 className="mb-10 text-center text-2xl font-bold text-primary md:text-3xl">
        Представляем вам лучшие кастомные прошивки, которые существуют для
        асиков BITMAIN на сегодняшний день от разработчика VNISH
      </h1>
      <FirmwareAdvantages />
      <FirmwareReview />
      <FirmwareList />
    </div>
  );
};

export default FirmwarePage;
