import { useDispatch } from "react-redux";
import { updateSingleAsic } from "../../../reducers/asicReducer";

import "./adminAsic.css";

export default function AdminAsic({ el, updatedIds }) {
  const dispatch = useDispatch();

  const updateHandler = (obj) => {
    dispatch(updateSingleAsic({ _id: el._id, ...obj }));
    updatedIds.current.push(el._id);
  };

  return (
    <tr
      className={[
        "adminAsicRow",
        (new Date() - new Date(el.updatedAt)) / (1000 * 3600 * 24) > 1
          ? "outdated"
          : null,
      ].join(" ")}
    >
      <td className="text-left">{el.name}</td>
      <td className="text-center">{el.release}</td>
      <td className="text-center">{el.hashrate}</td>
      <td className="text-center">{el.power}</td>
      <td className="text-center">{el.algo}</td>
      <td className="text-center">{el.revenue}</td>
      <td className="text-center">
        <input
          onChange={(event) => updateHandler({ price: event.target.value })}
          value={el.price}
          placeholder="Price"
          type={"number"}
        />
      </td>
      <td className="text-center">
        <input
          type={"checkbox"}
          checked={el.have}
          onChange={(event) => updateHandler({ have: event.target.checked })}
        />
      </td>
    </tr>
  );
}
