import $api from "../http";

export const getAsicsService = async (all = false) => {
  return await $api.get("/asic/list", { params: { all } });
};

export const updateAsicsService = async (asics) => {
  return await $api.post("/asic/update", {
    asics,
  });
};

export const connectToGetAsicsWebSocket = () => {
  return new WebSocket(
    `${process.env.REACT_APP_HOST.replace("http", "ws")}asics/list`
  );
};
