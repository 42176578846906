import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getImgs } from "../../actions/advertising";
import { setCurrentAdvertising } from "../../reducers/minersReducer";

import "./advertising.css";

export default function Advertising() {
  const currentImgs = useSelector((state) => state.miners.advertising);
  const currentAdvertising = useSelector(
    (state) => state.miners.currentAdvertising
  );

  const dispatch = useDispatch();

  const MINUTE_MS = 60000;

  useEffect(() => {
    dispatch(getImgs());
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(setCurrentAdvertising());
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <div className="advertisingContainer">
      <div className="advertisingImgWrapper">
        {currentImgs.length > 0 && (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://atlas-miner.com.ua/?utm_source=mmining&utm_campaign=mainbanner"
          >
            <img
              src={`${process.env.REACT_APP_HOST}${currentImgs[currentAdvertising].filePath}`}
              alt={"advertising"}
              width={1200}
              height={192}
            />
          </a>
        )}
      </div>
    </div>
  );
}
