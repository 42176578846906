import { setAdvertising } from "../reducers/minersReducer";
import {
  deleteImgService,
  getImgsService,
  uploadImgsService,
} from "../services/advertising.service";

export const getImgs = () => {
  return async (dispatch) => {
    try {
      const response = await getImgsService();
      dispatch(setAdvertising(response.data));
    } catch (e) {
      alert(e.response.data.message);
    }
  };
};

export const uploadImgs = async (files) => {
  try {
    const response = await uploadImgsService(files);
    alert(response.data.message);
  } catch (e) {
    alert(e.response.data.message);
  }
};

export const deleteImg = async (img) => {
  try {
    const response = await deleteImgService(img);
    alert(response.data.message);
  } catch (e) {
    alert(e.response.data.message);
  }
};
