import { useDispatch, useSelector } from "react-redux";
import { updateGpusBrandFilter } from "../../reducers/gpus.reducer";
import brands from "../../settings/gpuBrands.json";

import styles from "../../styles/gpus/profitability.module.css";

export default function GpusTableFilter() {
  const currentBrands = useSelector((state) => state.gpus.brands);

  const dispatch = useDispatch();

  const brandHandler = (brand) => {
    dispatch(updateGpusBrandFilter({ [brand]: !currentBrands[brand] }));
  };

  return (
    <div className={styles.buttons}>
      <button
        className={[
          styles.btn,
          styles.green,
          !currentBrands[brands.nvidia.title] ? styles.disabled : null,
        ].join(" ")}
        onClick={() => brandHandler(brands.nvidia.title)}
      >
        {brands.nvidia.title.toUpperCase()}
      </button>
      <button
        className={[
          styles.btn,
          styles.red,
          !currentBrands[brands.amd.title] ? styles.disabled : null,
        ].join(" ")}
        onClick={() => brandHandler(brands.amd.title)}
      >
        {brands.amd.title.toUpperCase()}
      </button>
    </div>
  );
}
