import { setStat } from "../reducers/statReducer";
import {
  firmwareDownloadService,
  firmwareStatService,
} from "../services/stat.service";

export const firmwareDownload = async (title) => {
  try {
    const response = await firmwareDownloadService(title);
    return response.data;
  } catch (e) {
    alert(e.response.data.message);
  }
};

export const firmwareStat = () => {
  return async (dispatch) => {
    try {
      const response = await firmwareStatService();
      dispatch(setStat(response.data));
    } catch (e) {
      alert(e.response.data.message);
    }
  };
};
