import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPowerCost } from "../../reducers/minersReducer";

export default function ProfitabilityInput({
  step = "1",
  min = "0",
  max = Infinity,
}) {
  const currentMiners = useSelector((state) => state.miners.cost);
  const dispatch = useDispatch();

  const handlerChange = (value) => {
    if (value === "") {
      value = min;
    }

    value = parseFloat(value);
    const _max = parseFloat(max);
    const _min = parseFloat(min);

    if (value > _max) value = _max;
    if (value < _min) value = _min;
    dispatch(setPowerCost(value));
  };

  return (
    <div className="profitabilityInputContainer">
      <label className="profitabilityLabel" htmlFor={"profitability"}>
        Стоимость э/э
      </label>
      <div className="profitabilityInputComponentContainer">
        <input
          id={"profitability"}
          inputMode={"numeric"}
          pattern={"[0-9]*"}
          type={"number"}
          className="profitabilityInput"
          value={currentMiners}
          onChange={(event) => handlerChange(event.target.value)}
          min={min}
          max={max}
          step={step}
        />
        <div className="profitabilityInputUnitsWrapper">
          <span className="profitabilityInputUnits">$/кВт⋅ч</span>
        </div>
      </div>
    </div>
  );
}
