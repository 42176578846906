import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsics, updateAsics } from "../../actions/asics";
import { miningTableGetRevenue } from "../../helpers/miningTable.helper";
import AdminAsic from "../admin/Asic/AdminAsic";

import "./panel.css";

export default function Panel() {
  const currentAsics = useSelector((state) => state.asics.asics);

  const dispatch = useDispatch();

  const updatedIds = useRef([]);
  const processing = useRef(false);

  useEffect(() => {
    dispatch(getAsics(true));
  }, [dispatch]);

  const handleSave = async () => {
    if (processing.current) return;
    processing.current = true;

    const response = await updateAsics(
      currentAsics.filter((el) =>
        [...new Set(updatedIds.current)].includes(el._id)
      )
    );

    processing.current = false;

    if (response !== undefined) {
      updatedIds.current = [];
      alert(response?.message);
    } else {
      alert("Error");
    }
  };

  const getRevenue = (asic) => {
    if (!asic.have) return -Infinity;
    return miningTableGetRevenue(asic);
  };

  const renderAsics = () => {
    const arr = currentAsics.sort((a, b) => getRevenue(b) - getRevenue(a));
    return arr.map((el, i) => (
      <AdminAsic key={i} el={el} updatedIds={updatedIds} />
    ));
  };

  return (
    <div className="panelContainer">
      <table className="panelTable">
        <thead>
          <tr>
            <th>Model</th>
            <th>Release Date</th>
            <th>Hashrate</th>
            <th>Power</th>
            <th>Algo</th>
            <th>Revenue 24h</th>
            <th>Price</th>
            <th>Have</th>
          </tr>
        </thead>
        <tbody>{renderAsics()}</tbody>
      </table>
      <div className="panelButtonWrapper">
        <div className="panelBtn panelBtn-black" onClick={() => handleSave()}>
          Save
        </div>
      </div>
    </div>
  );
}
