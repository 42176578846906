const SET_GPUS = "SET_GPUS";
const SET_BRAND = "SET_BRAND";
const UPDATE_GPUS = "UPDATE_GPUS";
const UPDATE_GPU = "UPDATE_GPU";

const defaultState = {
  arr: [],
  brands: {
    nvidia: true,
    amd: true,
  },
};

export default function gpusReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_GPUS:
      return {
        ...state,
        arr: action.payload,
      };
    case SET_BRAND: {
      const _brands = { ...state.brands };
      return {
        ...state,
        brands: Object.assign(_brands, action.payload),
      };
    }
    case UPDATE_GPUS:
      return {
        ...state,
        arr: state.arr.map((el) => {
          const obj = action.payload.find((value) => value._id === el._id);

          if (obj === undefined) {
            return el;
          } else {
            return obj;
          }
        }),
      };
    case UPDATE_GPU:
      return {
        ...state,
        arr: state.arr.map((el) =>
          el._id === action.payload._id ? Object.assign(el, action.payload) : el
        ),
      };
    default:
      return state;
  }
}

export const setGpus = (arr) => ({ type: SET_GPUS, payload: arr });

export const updateGpusBrandFilter = (obj) => ({
  type: SET_BRAND,
  payload: obj,
});

export const updateGpusList = (arr) => ({ type: UPDATE_GPUS, payload: arr });

export const updateSingleGpu = (obj) => ({ type: UPDATE_GPU, payload: obj });
