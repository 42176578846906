import { BrowserRouter, Routes, Route } from "react-router-dom";

import Admin from "./admin/Admin";
import Panel from "./Panel/Panel";
import AdminGpusPage from "../pages/admin/gpus";
import AdminImgPanel from "./adminImgPanel/AdminImgPanel";
import AdminStat from "@/app/admin/statistics/page";
import Miners from "./miners/Miners";
import Gratitude from "./Gratitude/Gratitude";
import FirmwarePageLayout from "@/app/firmware/layout";
import FirmwarePage from "@/app/firmware/page";
import Gpus from "../pages/gpus";
import ErrorPage from "../pages/404";

import "../styles/global.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Miners />} />
        <Route path="admin" element={<Admin />}>
          <Route path="asics" element={<Panel />} />
          <Route path="gpus" element={<AdminGpusPage />} />
          <Route path="advertising" element={<AdminImgPanel />} />
          <Route path="statistics" element={<AdminStat />} />
        </Route>
        <Route path="gratitude" element={<Gratitude />} />
        <Route path="firmware" element={<FirmwarePageLayout />}>
          <Route index={true} element={<FirmwarePage />} />
        </Route>
        <Route path="gpus" element={<Gpus />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
