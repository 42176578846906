import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { FC, useMemo } from "react";

type Props = {
  title: string;
  items: Array<{ title: string; statisticsKey: string }>;
  // TODO: Move function inside component and make monorepo package to share api interface
  getClicksCount: (id: string) => number;
};

const AdminStatisticsTable: FC<Props> = ({ title, items, getClicksCount }) => {
  const [totalClicksCount, clicksCount]: [number, Map<string, number>] =
    useMemo(() => {
      let totalClicks = 0;
      const clicks = new Map<string, number>();
      items.forEach((item) => {
        const count = getClicksCount(item.statisticsKey);
        clicks.set(item.statisticsKey, count);
        totalClicks += count;
      });
      return [totalClicks, clicks];
    }, [items, getClicksCount]);

  return (
    <div className="container w-full">
      <h2 className="text-center text-xl font-bold text-foreground">{title}</h2>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Имя</TableHead>
            <TableHead className="text-center">Количество кликов</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.statisticsKey}>
              <TableCell>{item.title}</TableCell>
              <TableCell className="text-center">
                {clicksCount.get(item.statisticsKey) ?? 0}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableCell>Всего:</TableCell>
          <TableCell className="text-center">{totalClicksCount}</TableCell>
        </TableFooter>
      </Table>
    </div>
  );
};

export default AdminStatisticsTable;
