import { FIRMWARE_ADVANTAGES } from "./advantages";
import { FC } from "react";
import { DotIcon } from "@radix-ui/react-icons";
import { BlockTitle } from "../block";
import { Separator } from "@/components/ui/separator";

type Props = {};

const FirmwareAdvantages: FC<Props> = () => {
  return (
    <div>
      <BlockTitle>Кастомные прошивки позволяют</BlockTitle>
      <Separator className="my-4" />
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        {FIRMWARE_ADVANTAGES.map((el, i) => (
          <div className="flex items-center gap-x-2 md:gap-x-4" key={i}>
            <DotIcon className="h-6 w-6 shrink-0" />
            <p className="text-base font-normal text-foreground">{el}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FirmwareAdvantages;
