import React from "react";

import "./coin.css";

export default function Coin({ img, price, priceChange, name, precision }) {
  const renderArrow = (src, alt) => {
    return (
      <img
        className={"navBarTopCryptoElementPercentArrow"}
        src={src}
        alt={alt}
      />
    );
  };

  return (
    <div className="navBarTopCryptoElementContainer">
      <img src={img} alt="bitcoin" />
      <div className="navBarTopCryptoElementWrapper">
        <div className="navBarTopCryptoElementName">{name}</div>
        <div className="navBarTopCryptoElementPrices">
          <div className="navBarTopCryptoElementPricesText">
            ${parseFloat(price).toFixed(precision)}
          </div>
          <div className="navBarTopCryptoElementPercent">
            {parseFloat(priceChange) === 0 &&
              renderArrow("/assets/img/arrow-zero.svg", "Arrow line")}
            {priceChange > 0 &&
              renderArrow("/assets/img/arrow-up-outlined.svg", "Arrow up")}
            {priceChange < 0 &&
              renderArrow("/assets/img/arrow-down-outlined.svg", "Arrow down")}
            <div
              className={[
                "navBarTopCryptoElementPricesText",
                priceChange > 0 ? "navBarTopCryptoElementPercentGreen" : null,
                priceChange < 0 ? "navBarTopCryptoElementPercentRed" : null,
              ].join(" ")}
            >
              {Math.abs(parseFloat(priceChange)).toFixed(2)}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
