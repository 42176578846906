import { useDispatch } from "react-redux";
import { updateSingleGpu } from "../../../reducers/gpus.reducer";
import brands from "../../../settings/gpuBrands.json";

import styles from "../../../styles/gpus/row.module.css";

export default function AdminGpusTableRow({ el, updatedIds }) {
  const dispatch = useDispatch();

  const updateHandler = (obj) => {
    dispatch(updateSingleGpu({ _id: el._id, ...obj }));
    updatedIds.current.push(el._id);
  };

  return (
    <tr
      className={[
        "adminAsicRow",
        (new Date() - new Date(el.updatedAt)) / (1000 * 3600 * 24) > 1
          ? "outdated"
          : null,
      ].join(" ")}
    >
      <td className="text-left">
        <span
          className={[
            styles.brand,
            el.brand === brands.nvidia.title.toUpperCase()
              ? styles.nvidia
              : null,
            el.brand === brands.amd.title.toUpperCase() ? styles.amd : null,
          ].join(" ")}
        >
          {el.brand}
        </span>
        {el.title}
      </td>
      <td className="text-center">{el.release}</td>
      <td className="text-center">{el.hashrate}</td>
      <td className="text-center">{el.revenue}</td>
      <td className="text-center">
        <input
          onChange={(event) => updateHandler({ price: event.target.value })}
          value={el.price}
          placeholder="Price"
          type={"number"}
        />
      </td>
      <td className="text-center">
        <input
          type={"checkbox"}
          checked={el.have}
          onChange={(event) => updateHandler({ have: event.target.checked })}
        />
      </td>
    </tr>
  );
}
