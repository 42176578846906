import { Link, NavLink } from "react-router-dom";
import pages from "../../settings/pages.json";

import styles from "../../styles/admin/header.module.css";
import hStyles from "../../styles/navbar/navbar.module.css";

export default function AdminNavBar() {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <Link className={hStyles.navBarBottomLogo} to={pages.index.url}>
          MONEY-MINING.COM
        </Link>
        <nav className={styles.adminNavBar}>
          <NavLink
            className={({ isActive }) =>
              [styles.adminNavBarLink, isActive ? styles.active : null].join(
                " ",
              )
            }
            to={pages.asics.url}
          >
            <p className={styles.adminNavBarButton}>Asics</p>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              [styles.adminNavBarLink, isActive ? styles.active : null].join(
                " ",
              )
            }
            to={pages.adminGpus.url}
          >
            <p className={styles.adminNavBarButton}>Gpus</p>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              [styles.adminNavBarLink, isActive ? styles.active : null].join(
                " ",
              )
            }
            to={pages.advertising.url}
          >
            <p className={styles.adminNavBarButton}>Advertising</p>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              [styles.adminNavBarLink, isActive ? styles.active : null].join(
                " ",
              )
            }
            to={pages.statistics.url}
          >
            <p className={styles.adminNavBarButton}>Statistics</p>
          </NavLink>
        </nav>
      </div>
    </header>
  );
}
