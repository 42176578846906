import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import Coin from "../Coin/Coin";
import { SidebarData } from "../../settings/sidebarData";
import pages from "../../settings/pages.json";
import ticker from "../../settings/navBarTickers.json";
import { getTickerPrice } from "../../actions/tickers";
import { connectToBinanceWebSocket } from "../../services/binance.service";

import styles from "../../styles/navbar/navbar.module.css";

export default function NavBar() {
  const unifyTicker = useCallback((el, info) => {
    return {
      key: el.symbol,
      img: el.icon,
      price: info?.c || info?.lastPrice || "0",
      priceChange: info?.P || info?.priceChangePercent || "0",
      name: el.name,
      precision: el.precision,
    };
  }, []);

  const [sidebar, setSidebar] = useState(false);
  const [tickers, setTickers] = useState(
    ticker.map((el) => unifyTicker(el, undefined))
  );

  const socket = useRef(null);

  useEffect(() => {
    getTickerPrice(ticker.map((el) => el.symbol)).then((result) => {
      if (!result) return;

      const arr = ticker.map((el) => {
        const info = result.find((obj) => obj.symbol === el.symbol);

        return unifyTicker(el, info);
      });

      setTickers(arr);
    });
  }, [unifyTicker]);

  useEffect(() => {
    socket.current = connectToBinanceWebSocket();

    socket.current.onmessage = (event) => {
      const data = JSON.parse(event.data);

      const arr = ticker.map((el) => {
        const info = data.find((obj) => obj.s === el.symbol);

        if (!info) return undefined;

        return unifyTicker(el, info);
      });

      setTickers((oldArr) => {
        return oldArr.map((oldValue) => {
          const newValue = arr.find((value) => value?.key === oldValue.key);
          if (!newValue) return oldValue;
          return newValue;
        });
      });
    };

    return () => {
      socket.current.close();
    };
  }, [unifyTicker]);

  const showSidebar = () => {
    setSidebar(!sidebar);
    if (!sidebar) {
      document.body.style.left = "0px";
      document.body.style.top = -window.pageYOffset + "px";
      document.body.classList.add("noscroll");
    } else {
      let yOffset = -parseInt(document.body.style.top.slice(0, -2));
      document.body.style.left = "";
      document.body.style.top = "";
      document.body.classList.remove("noscroll");
      window.scrollTo({
        left: 0,
        top: yOffset,
        behavior: "instant",
      });
    }
  };

  return (
    <div className={styles.navBarContainer}>
      <div className={styles.navBarTopContainer}>
        <div className={styles.logo}>
          <Link className={styles.navBarBottomLogo} to={pages.index.url}>
            MONEY-MINING.COM
          </Link>
        </div>
        <span className={styles.navBarTopCrypto}>
          {tickers.map((el, i) => (
            <Coin key={i} {...el} />
          ))}
        </span>
        <div className={styles.right}>
          <button className={styles.menu} onClick={showSidebar}>
            <p>Меню</p>
            <div
              className={[styles.hamburger, sidebar ? styles.open : null].join(
                " "
              )}
            >
              <span className={styles.line}></span>
              <span className={styles.line}></span>
              <span className={styles.line}></span>
            </div>
          </button>
        </div>
        <div
          className={[
            styles.navBarMenuContainer,
            sidebar ? styles.active : null,
          ].join(" ")}
          onClick={showSidebar}
        >
          <nav
            className={[styles.navBarMenu, sidebar ? styles.active : null].join(
              " "
            )}
          >
            <ul className={styles.navBarMenuItems}>
              <li className={styles.navBarMenuToggle}>
                <button className={styles.navBarMenuBars}>
                  <img
                    src={"/assets/img/close-outlined-white.svg"}
                    alt={"Close icon"}
                  />
                </button>
              </li>
              {SidebarData.map((el, i) => (
                <li key={i} className={el.cName}>
                  <Link to={el.path}>
                    {el.icon}
                    <span>{el.title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
