import Advertising from "../components/advertising/Advertising";
import GpusProfitability from "../components/gpus/profitability";
import GpusTable from "../components/gpus/table";
import Layout from "../components/layouts";
import GpusModal from "../components/modal/gpus.modal";

export default function Gpus() {
  return (
    <Layout>
      <Advertising />
      <GpusProfitability />
      <GpusTable />
      <GpusModal />
    </Layout>
  );
}
