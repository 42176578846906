import React from "react";
import { Link } from "react-router-dom";
import pages from "../../settings/pages.json";

import "./gratitude.css";

export default function Gratitude() {
  return (
    <div className="gratitudeContainer">
      <Link className="gratitudeLink" to={pages.index.url}>
        <span>Вернуться на сайт</span>
      </Link>
      <p className="gratitudeText">
        Информацию принял. Мои люди с Вами свяжутся
      </p>
      <img
        src={"/assets/img/gratitude-bg.jpg"}
        alt="McConaughey"
        className="gratitudeImg"
      />
    </div>
  );
}
