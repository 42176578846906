import { FC, useCallback } from "react";
import { FIRMWARE_LIST } from "@/constants/firmware-list";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { BlockTitle } from "../block";
import { Link } from "@/components/ui/link";
import { firmwareDownload } from "@/actions/stat";

type Props = {};

const FirmwareList: FC<Props> = () => {
  const statisticsHandler = useCallback(async (key: string) => {
    await firmwareDownload(key);
  }, []);

  return (
    <div className="space-y-8">
      <BlockTitle>Прошивки</BlockTitle>
      <Card>
        <CardHeader>
          <CardTitle>
            Прошивки для разгона 19й серии BITMAIN от разработчика VNISH
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableBody>
              {FIRMWARE_LIST.map((firmware, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <h3 className="text-base font-normal">{firmware.title}</h3>
                  </TableCell>
                  <TableCell className="text-center">
                    <Button asChild>
                      <Link
                        to={firmware.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        reloadDocument={true}
                        onClick={() =>
                          statisticsHandler(firmware.statisticsKey)
                        }
                      >
                        Скачать
                      </Link>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default FirmwareList;
