import React from "react";
import ProfitabilityInput from "./profitabilityInput";

import "./profitability.css";

export default function Profitability() {
  return (
    <div className="profitabilityContainer">
      <div className="profitabilityContentWrapper">
        <h1 className="profitabilityH1">
          АКТУАЛЬНАЯ ДОХОДНОСТЬ И ОКУПАЕМОСТЬ АСИКОВ
        </h1>
        <ProfitabilityInput step={"0.05"} max={"100"} />
      </div>
    </div>
  );
}
