import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useMatch } from "react-router-dom";
import { auth } from "../../actions/user";
import Login from "../Login/Login";
import pages from "../../settings/pages.json";
import AdminNavBar from "../adminNavBar/AdminNavBar";

import "./admin.css";

export default function Admin() {
  const isAuth = useSelector((state) => state.user.isAuth);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const match = useMatch(pages.admin.url);

  useEffect(() => {
    dispatch(auth()).then((result) => {
      setLoading(false);
      if (!result) return;
      match && navigate(pages.asics.url);
    });
  }, [dispatch, navigate, match]);

  return (
    <>
      <AdminNavBar />
      {loading && (
        <div className="DotLoader">
          <h1>Loading...</h1>
        </div>
      )}
      {!isAuth && !loading && <Login />}
      {isAuth && <Outlet />}
    </>
  );
}
