export const FIRMWARE_REVIEWS = [
  {
    id: "RPeM72_HzXc",
    title:
      "Разгон асика S17+ до 90 TH а также других асиков 17й серии от BITMAIN - полная инструкция",
  },
  {
    id: "G28x4PAwxwY",
    title: "Разгон S17 до 70 TH - пошаговая инструкция и тест прошивки",
  },
  {
    id: "uzPf6njQeI0",
    title: "Новая прошивка для Asic S9 - куча фишек и минус 150 ватт",
  },
];
