import { setAsic } from "../reducers/asicReducer";
import { getAsicsService, updateAsicsService } from "../services/asics.service";

export const getAsics = (isAll) => {
  return async (dispatch) => {
    try {
      const response = await getAsicsService(isAll);
      dispatch(setAsic(response.data));
    } catch (e) {
      alert(e.response.data.message);
    }
  };
};

export const updateAsics = async (asics) => {
  try {
    const response = await updateAsicsService(asics);
    return response.data;
  } catch (e) {
    console.log(e.response?.data?.message);
  }
};
