import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authorize } from "../../actions/user";
import pages from "../../settings/pages.json";

import "./login.css";

export default function Login() {
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authorizeHandler = () => {
    dispatch(authorize(userName, userPassword)).then((result) => {
      if (result) navigate(pages.asics.url);
    });
  };

  return (
    <div className="loginContainer">
      <form>
        <div className="loginForm-group">
          <label>User Name</label>
          <input
            type="text"
            className="loginForm-control"
            placeholder="User Name"
            onChange={(event) => setUserName(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                authorizeHandler();
              }
            }}
            value={userName}
            autoFocus={true}
          />
        </div>
        <div className="loginForm-group">
          <label>Password</label>
          <input
            type="password"
            className="loginForm-control"
            placeholder="Password"
            onChange={(event) => setUserPassword(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                authorizeHandler();
              }
            }}
            value={userPassword}
          />
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            authorizeHandler();
          }}
          type="submit"
          className="loginBtn loginBtn-black"
        >
          Login
        </button>
      </form>
    </div>
  );
}
