import $api from "../http";

export const getImgsService = async () => {
  return await $api.get("/img/list");
};

export const uploadImgsService = async (files) => {
  return await $api.post("/img/load", files);
};

export const deleteImgService = async (img) => {
  return await $api.post("/img/delete", { img });
};
