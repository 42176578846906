import MainLayout from "../layouts/main";

import styles from "../../styles/404/404.module.css";

export default function ErrorPageComponent() {
  return (
    <div className={["block", styles.block].join(" ")}>
      <MainLayout>
        <div className={"container-legacy"}>
          <div className={styles.error}>
            <p className={styles.code}>404</p>
            <span className={styles.line} />
            <p>This page could not be found</p>
          </div>
        </div>
      </MainLayout>
    </div>
  );
}
