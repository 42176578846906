import { useSelector } from "react-redux";
import Modal from "../modal/Modal";
import MiningModalForm from "./form";
import MiningModalSocialMedia from "./socialMedia";

export default function AsicRowModal() {
  const currentAsics = useSelector((state) => state.asics.asics);
  const currentModal = useSelector((state) => state.miners.modal);

  return (
    <Modal>
      <div className="asicRowModal">
        <div className="asicRowModalText">
          Заказать асик
          <span className="asicRowModalTextName">
            {currentAsics[currentModal.id] &&
              " " + currentAsics[currentModal.id]?.name + " "}
          </span>
          в компани ATLAS ANTMINER или получить консультацию по данной модели
        </div>
        <MiningModalForm
          name={currentAsics[currentModal.id]?.name}
          id={currentModal.id}
        />
        <div className="asicRowModalLine" />
        <MiningModalSocialMedia />
      </div>
    </Modal>
  );
}
