import axios from "axios";
import $api from "../http";

export const authorizeService = async (login, password) => {
  return await $api.post("/auth/login", {
    login,
    password,
  });
};

export const authService = async () => {
  return await axios.get(`${process.env.REACT_APP_HOST}api/auth/auth`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    withCredentials: true,
  });
};
