export default function MiningModalSocialMedia() {
  return (
    <div className="asicRowModalContactWrapper">
      <div className="asicRowModalTextContact">
        или напишите нам в вайбер/телеграм
      </div>
      <div className="asicRowModalSmWrapper">
        <div className="asicRowModalSm">
          <a
            href="viber://chat?number=380671387038"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={"asicRowModalSmIMG"}
              src={"/assets/img/viber-icon-black.svg"}
              alt={"Viber icon"}
            />
          </a>
          <a href="http://t.me/alfacore" target="_blank" rel="noreferrer">
            <img
              className={"asicRowModalSmIMG"}
              src={"/assets/img/telegram-icon-black.svg"}
              alt={"Telegram icon"}
            />
          </a>
        </div>
      </div>
    </div>
  );
}
