import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../reducers/minersReducer";

import "./modal.css";

export const handleOpenModal = (dispatch, id) => {
  dispatch(setModal({ id: id, active: true }));
  document.body.style.left = "0px";
  document.body.style.top = -window.pageYOffset + "px";
  document.body.classList.add("noscroll");
};

export const handleCloseModal = (dispatch, id) => {
  dispatch(setModal({ id: id, active: false }));
  let yOffset = -parseInt(document.body.style.top.slice(0, -2));
  document.body.style.left = "";
  document.body.style.top = "";
  document.body.classList.remove("noscroll");
  window.scrollTo({
    left: 0,
    top: yOffset,
    behavior: "instant",
  });
};

export default function Modal({ children }) {
  const currentModal = useSelector((state) => state.miners.modal);
  const dispatch = useDispatch();

  const isActive = () => {
    return currentModal.active;
  };

  return (
    <div className={["modal", currentModal.active ? "active" : null].join(" ")}>
      <div
        className={["modalContent", currentModal.active ? "active" : null].join(
          " "
        )}
      >
        {children}
      </div>
      <div
        className={["overlay", isActive() ? "active" : null].join(" ")}
        onMouseDown={() => handleCloseModal(dispatch, currentModal.id)}
      />
    </div>
  );
}
