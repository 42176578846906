import React from "react";

import NavBar from "../NavBar/NavBar";
import Advertising from "../advertising/Advertising";
import Profitability from "../profitability/Profitability";
import AsicsTable from "../AsicsTable/AsicsTable";

export default function Miners() {
  return (
    <>
      <NavBar />
      <Advertising />
      <Profitability />
      <AsicsTable />
    </>
  );
}
