import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
import { thunk } from "redux-thunk";

import userReducer from "./userReducer";
import asicReducer from "./asicReducer";
import minersReducer from "./minersReducer";
import gpusReducer from "./gpus.reducer";
import statReducer from "./statReducer";

const rootReducer = combineReducers({
  user: userReducer,
  asics: asicReducer,
  gpus: gpusReducer,
  miners: minersReducer,
  stat: statReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevToolsDevelopmentOnly(applyMiddleware(thunk)),
);
