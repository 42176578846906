import ProfitabilityInput from "../profitability/profitabilityInput";
import GpusTableFilter from "./filter";

import styles from "../../styles/gpus/profitability.module.css";

export default function GpusProfitability() {
  return (
    <div className="profitabilityContainer">
      <div
        className={["profitabilityContentWrapper", styles.wrapper].join(" ")}
      >
        <h1 className="profitabilityH1">
          АКТУАЛЬНАЯ ДОХОДНОСТЬ И ОКУПАЕМОСТЬ ВИДЕОКАРТ
        </h1>
        <div className={styles.controls}>
          <GpusTableFilter />
          <ProfitabilityInput step={"0.05"} max={"100"} />
        </div>
      </div>
    </div>
  );
}
