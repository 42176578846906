import { setGpus } from "../reducers/gpus.reducer";
import {
  getGpusListService,
  updateGpusService,
} from "../services/gpus.service";

export const getGpusList = (isAll) => {
  return async (dispatch) => {
    try {
      const response = await getGpusListService(isAll);
      dispatch(setGpus(response.data));
    } catch (e) {
      console.log(e.response?.data?.message);
    }
  };
};

export const updateGpus = async (gpus) => {
  try {
    const response = await updateGpusService(gpus);
    return response.data;
  } catch (e) {
    console.log(e.response?.data?.message);
  }
};
