import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteImg, getImgs, uploadImgs } from "../../actions/advertising";

import "./adminImgPanel.css";

export default function AdminImgPanel() {
  const currentImgs = useSelector((state) => state.miners.advertising);
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);
  const [inputRef, setInputRef] = useState(Object);

  useEffect(() => {
    dispatch(getImgs());
  }, [dispatch]);

  const handleDeleteImg = async (i) => {
    await deleteImg(currentImgs[i]).then(() => {
      dispatch(getImgs());
    });
  };

  const handleAddFiles = async () => {
    if (files.length > 0) {
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      await uploadImgs(formData).then(() => {
        inputRef.value = "";
        setFiles([]);
        dispatch(getImgs());
      });
      return;
    }
  };

  return (
    <div className="adminIngPanel">
      <div className="adminIngPanelInputContainer">
        <div className="adminIngPanelInputWrapper">
          <input
            ref={(ref) => setInputRef(ref)}
            type="file"
            multiple
            onChange={(e) => {
              setFiles(e.target.files);
            }}
          />
        </div>
        <div className="adminIngPanelBtnWrapper">
          <div className="adminIngPanelBtn" onClick={handleAddFiles}>
            Добавить
          </div>
        </div>
      </div>
      <div className="adminIngPanelImgContainer">
        {currentImgs.map((el, i) => (
          <div key={i} className="adminIngPanelImgWrapper">
            <img
              alt="advertising"
              src={`${process.env.REACT_APP_HOST}${el.filePath}`}
            />
            <div className="adminIngPanelBtnWrapper">
              <div
                className="adminIngPanelBtn"
                onClick={() => handleDeleteImg(i)}
              >
                Удалить
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
