import { getTickerPriceService } from "../services/ticker.service";

export const getTickerPrice = async (symbols) => {
  try {
    const response = await getTickerPriceService(symbols);
    return response.data;
  } catch (e) {
    console.log(e.response);
  }
};
