import $api from "../http";

export const firmwareDownloadService = async (title) => {
  return await $api.post("/stat/click", {
    title,
  });
};

export const firmwareStatService = async () => {
  return await $api.get("/stat/list");
};
