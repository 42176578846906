const SET_STAT = "SET_STAT";
const SET_PERIOD = "SET_PERIOD";

const defaultState = {
  stat: [],
  period: 0, // 0 - Все время, 1 - Месяц, 2 - Неделя, 3 - День
};

export default function statReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_STAT:
      return {
        ...state,
        stat: action.payload,
      };
    case SET_PERIOD:
      return {
        ...state,
        period: action.payload,
      };
    default:
      return state;
  }
}

export const setStat = (data) => ({ type: SET_STAT, payload: data });
export const setPeriod = (number) => ({ type: SET_PERIOD, payload: number });
