import "./styles/main.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { firmwareStat } from "@/actions/stat";
import { setPeriod } from "@/reducers/statReducer";
import AdminStatisticsTable from "./components/statistics-table";
import { FIRMWARE_LIST } from "@/constants/firmware-list";

//! Add context and store total there if more then one table
export default function AdminStat() {
  const currentStat = useSelector((state) => state.stat.stat);
  const currentPeriod = useSelector((state) => state.stat.period);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(firmwareStat());
  }, [dispatch]);

  // TODO: Move inside component
  const getClicksCount = (id) => {
    if (currentPeriod === 0) {
      // All time
      const item = currentStat.find((el, i) => {
        if (el.title === id) {
          return true;
        } else {
          return false;
        }
      });
      if (!item) {
        return 0;
      }
      return item.count;
    }
    if (currentPeriod === 1) {
      // Month
      const item = currentStat.find((el, i) => {
        if (el.title === id) {
          return true;
        } else {
          return false;
        }
      });
      if (!item) {
        return 0;
      }
      let d = new Date();
      d.setMonth(d.getMonth() - 1);
      const period = item.past.find((el, i) => {
        if (new Date(el.date) - d > 0) {
          return true;
        } else {
          return false;
        }
      });
      if (!period) {
        return 0;
      } else {
        return item.count - period.count;
      }
    }
    if (currentPeriod === 2) {
      // Week
      const item = currentStat.find((el, i) => {
        if (el.title === id) {
          return true;
        } else {
          return false;
        }
      });
      if (!item) {
        return 0;
      }
      let d = new Date();
      d.setDate(d.getDate() - 7);
      const period = item.past.find((el, i) => {
        if (new Date(el.date) - d > 0) {
          return true;
        } else {
          return false;
        }
      });
      if (!period) {
        return 0;
      } else {
        return item.count - period.count;
      }
    }
    if (currentPeriod === 3) {
      // Week
      const item = currentStat.find((el, i) => {
        if (el.title === id) {
          return true;
        } else {
          return false;
        }
      });
      if (!item) {
        return 0;
      }
      let d = new Date();
      d.setDate(d.getDate() - 1);
      const period = item.past.find((el, i) => {
        if (new Date(el.date) - d > 0) {
          return true;
        } else {
          return false;
        }
      });
      if (!period) {
        return 0;
      } else {
        return item.count - period.count;
      }
    }
  };

  return (
    <div>
      <div className="adminStatPeriodContainer">
        <div
          className={
            currentPeriod === 0
              ? "adminStatPeriodButton active"
              : "adminStatPeriodButton"
          }
          onClick={() => currentPeriod !== 0 && dispatch(setPeriod(0))}
        >
          За все время
        </div>
        <div
          className={
            currentPeriod === 1
              ? "adminStatPeriodButton active"
              : "adminStatPeriodButton"
          }
          onClick={() => currentPeriod !== 1 && dispatch(setPeriod(1))}
        >
          За последний месяц
        </div>
        <div
          className={
            currentPeriod === 2
              ? "adminStatPeriodButton active"
              : "adminStatPeriodButton"
          }
          onClick={() => currentPeriod !== 2 && dispatch(setPeriod(2))}
        >
          За последнюю неделю
        </div>
        <div
          className={
            currentPeriod === 3
              ? "adminStatPeriodButton active"
              : "adminStatPeriodButton"
          }
          onClick={() => currentPeriod !== 3 && dispatch(setPeriod(3))}
        >
          За сегодня
        </div>
      </div>
      <div className="mb-14 mt-4">
        <AdminStatisticsTable
          title="Прошивки для разгона 19й серии BITMAIN от разработчика VNISH"
          items={FIRMWARE_LIST}
          getClicksCount={getClicksCount}
        />
      </div>
      {/* <div className="adminStatAllStatContainer">
        <div className="adminStatAllStat">
          Всего кликов:{" "}
          <span>
            {Object.keys(firmwareData)
              .map((key) => getAllClicksCount(firmwareData[key].statisticKey))
              .reduce((a, b) => a + b, 0)}
          </span>
        </div>
      </div> */}
    </div>
  );
}
