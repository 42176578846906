const SET_ASIC = "SET_ASIC";
const UPDATE_ASIC = "UPDATE_ASIC";
const UPDATE_ASICS = "UPDATE_ASICS";

const defaultState = {
  asics: [],
};

export default function asicReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_ASIC:
      return {
        ...state,
        asics: action.payload,
      };
    case UPDATE_ASIC:
      return {
        ...state,
        asics: state.asics.map((el) =>
          el._id === action.payload._id ? Object.assign(el, action.payload) : el
        ),
      };
    case UPDATE_ASICS:
      return {
        ...state,
        asics: state.asics.map((el) => {
          const obj = action.payload.find((value) => value._id === el._id);

          if (obj === undefined) {
            return el;
          } else {
            return obj;
          }
        }),
      };
    default:
      return state;
  }
}

export const setAsic = (asic) => ({ type: SET_ASIC, payload: asic });

export const updateSingleAsic = (asic) => ({
  type: UPDATE_ASIC,
  payload: asic,
});

export const updateAsicsList = (arr) => ({ type: UPDATE_ASICS, payload: arr });
