import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGpusList } from "../../actions/gpus";
import {
  miningTableSort,
  tableHandleFilter,
} from "../../helpers/miningTable.helper";
import GpusTableRow from "./row";
import brands from "../../settings/gpuBrands.json";
import { updateGpusList } from "../../reducers/gpus.reducer";
import { connectToGetGpusWebSocket } from "../../services/gpus.service";

export default function GpusTable() {
  const currentGpus = useSelector((state) => state.gpus.arr);
  const currentMiners = useSelector((state) => state.miners.cost);
  const currentFilter = useSelector((state) => state.miners.filer);
  const currentReversed = useSelector((state) => state.miners.filterReversed);
  const currentBrands = useSelector((state) => state.gpus.brands);

  const socket = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGpusList());
  }, [dispatch]);

  useEffect(() => {
    socket.current = connectToGetGpusWebSocket();

    socket.current.onmessage = (event) => {
      const data = JSON.parse(event.data);

      dispatch(updateGpusList(data));
    };

    return () => {
      socket.current.close();
    };
  }, [dispatch]);

  const handleFilter = (value) => {
    tableHandleFilter(dispatch, value, currentFilter, currentReversed);
  };

  const renderGpus = () => {
    const arr = miningTableSort(
      currentGpus,
      currentMiners,
      currentFilter,
      currentReversed
    );

    return arr.map((el, i) => {
      if (!el.have) return null;
      if (
        !currentBrands[brands.nvidia.title] &&
        el.brand === brands.nvidia.title.toUpperCase()
      ) {
        return null;
      }
      if (
        !currentBrands[brands.amd.title] &&
        el.brand === brands.amd.title.toUpperCase()
      ) {
        return null;
      }
      return <GpusTableRow key={i} id={i} el={el} />;
    });
  };

  return (
    <div className="asicTableContainer">
      <table className="asicTable">
        <thead>
          <tr>
            <th className="text-left">Модель</th>
            <th className="text-center">Дата выпуска</th>
            <th
              className={[
                "text-right",
                "asicTableSelectable",
                currentFilter === 1 ? "asicRowSelected" : null,
              ].join(" ")}
              onClick={() => handleFilter(1)}
            >
              Хешрейт
            </th>
            <th
              className={[
                "text-right",
                "asicTableSelectable",
                currentFilter === 3 ? "asicRowSelected" : null,
              ].join(" ")}
              onClick={() => handleFilter(3)}
            >
              Доход за 24 часа
            </th>
            <th
              className={[
                "text-right",
                "asicTableSelectable",
                currentFilter === 4 ? "asicRowSelected" : null,
              ].join(" ")}
              onClick={() => handleFilter(4)}
            >
              Прибыль за 24 часа
            </th>
            <th
              className={[
                "text-center",
                "asicTableSelectable",
                currentFilter === 5 ? "asicRowSelected" : null,
              ].join(" ")}
              onClick={() => handleFilter(5)}
            >
              Цена под ключ
            </th>
            <th
              className={[
                "text-left",
                "asicTableSelectable",
                currentFilter === 6 ? "asicRowSelected" : null,
              ].join(" ")}
              onClick={() => handleFilter(6)}
              colSpan={2}
            >
              Окупаемость, мес
            </th>
          </tr>
        </thead>
        <tbody>{renderGpus()}</tbody>
      </table>
    </div>
  );
}
