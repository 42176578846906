import { setUser } from "../reducers/userReducer";
import { authorizeService, authService } from "../services/user.service";

// export const registration = async (login, password, role) => {
//   try {
//     const response = await $api.post("/auth/registration", {
//       login,
//       password,
//       role,
//     });
//     alert(response.data.message);
//   } catch (e) {
//     alert(e.response.data.message);
//   }
// };

export const authorize = (login, password) => {
  return async (dispatch) => {
    try {
      const response = await authorizeService(login, password);
      dispatch(setUser(response.data.user));
      localStorage.setItem("token", response.data.token);
      return true;
    } catch (e) {
      alert(e.response.data.message);
      return false;
    }
  };
};

export const auth = () => {
  return async (dispatch) => {
    try {
      const response = await authService();
      dispatch(setUser(response.data.user));
      localStorage.setItem("token", response.data.token);
      return true;
    } catch (e) {
      localStorage.removeItem("token");
      return false;
    }
  };
};
