import { useDispatch } from "react-redux";
import { sendForm } from "../../actions/mails";
import { useNavigate } from "react-router-dom";
import { handleCloseModal } from "../modal/Modal";
import pages from "../../settings/pages.json";
import { useRef, useState } from "react";

export default function MiningModalForm({ name, id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [phone, setPhone] = useState("");
  const [note, setNote] = useState("");

  const [errMsgPhone, setErrMsgPhone] = useState(false);

  const processing = useRef(false);

  const handleFormSend = async (e) => {
    e.preventDefault();

    if (processing.current) return;

    if (!handleValidation()) return;

    processing.current = true;
    await sendForm(name, phone, note);
    processing.current = false;

    handleCloseModal(dispatch, id);
    navigate(pages.gratitude.url);
  };

  const handleValidation = () => {
    const _phone = phone.trim();

    let err = 0;

    if (_phone.length <= 0) {
      setErrMsgPhone(true);
      err++;
    } else {
      setErrMsgPhone(false);
    }

    if (err > 0) {
      return false;
    } else {
      return true;
    }
  };

  const changeHandler = (e, setValue) => {
    setValue(e.target.value);
  };

  return (
    <form
      className="asicRowModalInputsWrapper"
      onSubmit={(e) => handleFormSend(e)}
    >
      <input
        placeholder="Telegram / Email"
        name="Email"
        autoComplete="email"
        type={"text"}
        value={phone}
        onChange={(e) => changeHandler(e, setPhone)}
      />
      <label className={errMsgPhone ? "asicRowModalErrMsg" : "display-none"}>
        Укажите, пожалуйста, Telegram или Email
      </label>
      <textarea
        className="mt-4 px-3 py-[6px]"
        placeholder="Коментарий"
        value={note}
        onChange={(e) => changeHandler(e, setNote)}
      />
      <div className="asicRowModalBtnWrapper">
        <button type="submit" className="asicRowModalBtn">
          Отправить
        </button>
      </div>
    </form>
  );
}
