export const FIRMWARE_ADVANTAGES = [
  "Увеличить или уменьшить хешрейт асика (разогнать для большей производительности или наоборот, уменьшить шум и потребление)",
  "Подбор индивидуально напряжения на каждой плате и частот каждого чипа автоматически или вручную (можно снизить нагрузку на проблемные чипы. Соответственно можно видеть есть ли проблемные чипы)",
  "Экономить 150-450 ватт в час на каждом асике (прошивка снижает потребление на той же производительности)",
  "Защита от вирусов : новый веб-сервис с защитой от угроз – постоянный мониторинг и обновление сигнатур онлайн.",
  "Обновленный интерфейс управления со встроенным ваттметром и счётчиком!",
  "Dev Fee – награда разработчику менее 1,5% от общего хэшрейта, параллельно, без прерывания основного майнинга.",
  "Гибкая работа с кулерами - уменьшение шума если позволяет температура.",
  "Снижение профилей при перегреве. Или ручной выбор предельной температуры работы.",
];
