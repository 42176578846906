import { sendFormService } from "../services/mails.service";

export const sendForm = async (model, phone, note) => {
  try {
    const response = await sendFormService(model, phone, note);
    return response.data;
  } catch (e) {
    alert(e.response.data.message);
  }
};
